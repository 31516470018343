/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM041P
 * 화면설명 : 일련번호 조회 팝업
 * 작 성 일 : 2022.12.28
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="일련번호 조회" :show-title="true" type="subpage">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">

          <!-- 일련번호 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="sch-box">
            <mo-decimal-field numeric class="form-input-name" v-model="cnsltWcnstPblNo" ref="srchNumRef" :rules="validateRulePblNo" underline clearable
                            placeholder="일련번호 9자리를 입력해주세요" mask="#########" @keyup.enter="fn_SearchPblNo"/>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SearchPblNo">조회하기</mo-button>
            </div>
          </ur-box-container>  

        </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
  ***********************************************************************************/
  import MSPCM042P from '@/ui/cm/MSPCM042P' // 일련번호 선택 팝업

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
    // 현재 화면명
    name: "MSPCM041P",
    // 현재 화면 ID
    screenId: "MSPCM041P",
    props: {
      pCustInfo: { // 고객 정보
        type: Object,
        default: {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
    data () {
      return {
        title: '일련번호 조회', // 화면명

        cnsltWcnstPblNo: '', // 입력한 일련번호
        isChkPblNo: false, // 입력 일련번호 자리수 체크

        popupObj: {}, // Popup Object
        lv_UserInfo: {}, // 사용자 정보
        
        validateRulePblNo: [ // 일련번호 Rule 체크
          v => !!v || '9자리의 일련번호를 입력하세요',
          () => this.isChkPblNo || '9자리의 일련번호를 입력하세요', // 
        ],
      };
    },
    /***********************************************************************************
     * watch 정의 영역                                                                 *
    ***********************************************************************************/
    watch: {
      // 일련번호
      cnsltWcnstPblNo() { // 입력시, 체크
        let cnsltWcnstPblNo = this.cnsltWcnstPblNo.trim()

        if (cnsltWcnstPblNo !== '' && cnsltWcnstPblNo.length > 8) {
          this.isChkPblNo = true
        } else {
          this.isChkPblNo = false
        }
        return this.isChkPblNo
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM041P')

      // 화면 진입시, 키패드 활성화
      this.$refs['srchNumRef'].focus()
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
    methods: {
      /*********************************************************
       * @function fn_Init
       * @notice   화면진입시, 최초 실행 함수
       *           초기 셋팅 및 설정
      *********************************************************/
      fn_Init () {
        const lv_Vm = this

        lv_Vm.$nextTick(() => {
          lv_Vm.$refs['srchNumRef'].focus()
        });

      },
      /******************************************************************************
       * @function  fn_SearchPblNo
       * @notice    조회하기 버튼 클릭 이벤트 Function
      ******************************************************************************/
      fn_SearchPblNo () {
        this.$refs.srchNumRef.validate()

        if (this.isChkPblNo) {
          this.fn_OpenPop()
        }
      },
      /******************************************************************************
       * @function  fn_OpenPop
       * @notice    일련번호 선택 팝업 호출
      ******************************************************************************/
      fn_OpenPop () {
        const lv_Vm = this

        // 일련번호 선택 팝업창 띄우기
        this.popupObj = lv_Vm.$bottomModal.open(MSPCM042P, {
          properties: {
            pCustInfo: this.pCustInfo,
            pCnsltWcnstPblNo: lv_Vm.cnsltWcnstPblNo
          },
          listeners: {
            onPopupConfirm: (pData) => {
              lv_Vm.$bottomModal.close(lv_Vm.popupObj)
              lv_Vm.$emit('onPopupConfirm', pData)
            },
            onPopupCancel: () => {
              lv_Vm.cnsltWcnstPblNo = ''
              lv_Vm.$bottomModal.close(lv_Vm.popupObj)
            }
          }
        });

      }
    } // ::Methods End
  };
</script>

/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM046P
 * 화면설명 : 고객선택 팝업
 * 접근권한 : 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일 : 2022.06.10
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="고객선택" :show-title="true" type="subpage" :topButton="true">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-radio-list2 customer-list-ty"><!-- 퍼블0525 : 고객선택 통일화 --> 
        <mo-list :list-data="CnsntCustList">
          <template #list-item="{ item }">
            <mo-list-item :class="{'on' : selectRdo == item.chnlCustId}">
              <ur-box-container alignV="start" componentid="" direction="row" class="w100"><!-- 퍼블0525 : 고객선택 통일화 --> 
              
                <!-- 라디오 버튼 영역 -->
                <mo-radio v-model="selectRdo" :value="item.chnlCustId" @input="fn_CheckSelectedItem(item)"/>

                <!-- 체크박스 영역 확인 필요.. -->
                <div class="list-item__contents" @click="fn_CheckSelectedItem(item)">
                  <div class="list-item__contents__title">
                    <!-- 고객명 -->
                    <span class="name">{{item.custNm}}</span>
                    <mo-badge class="badge-sample-badge" text="service" shape="status">{{ item.unAprvCustNm }}</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 생년월일 -->
                    <span class="crTy-bk1">{{ $commonUtil.dateDashFormat(item.birthNo) }}</span><em>|</em>
                    <!-- 성별 -->
                    <span class="crTy-bk1">{{ item.sxdsCdNm }}</span><em>|</em>
                    <!-- 나이 -->
                    <span class="crTy-bk1">{{ item.age }}세</span>
                  </div>
                  <div class="list-item__contents__info"><!-- 퍼블0525 : 고객선택 통일화 --> 
                    <span class="crTy-bk1">필수컨설팅동의<b :class="{'crTy-blue2':item.infoPrcusMcnstYn==='Y', 'crTy-orange2':item.infoPrcusMcnstYn==='N'}" class="pl10">
                        {{item.infoPrcusMcnstYn}}
                      </b>
                    </span><em>|</em>
                    <span class="crTy-bk1">마케팅동의<b :class="{'crTy-blue2':item.infoPrcusMktYn==='Y', 'crTy-orange2':item.infoPrcusMktYn==='N'}" class="pl10">
                        {{item.infoPrcusMktYn}}
                      </b>
                    </span>
                  </div>
                </div>
              </ur-box-container>
            </mo-list-item>
          </template>
        </mo-list>
        <!-- 맨 아래 여백 (데이터 짤림 방지) -->
        <div class="ns-height50"></div>
      </ur-box-container>

      <!-- 검색 결과 없을 경우 -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancle">취소</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmContent" :disabled="selectRdo === ''">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    // 현재 화면명
    name: "MSPCM046P",
    // 현재 화면 ID
    screenId: "MSPCM046P",
    props: {
      // 고객명
      pCustNm: {
        type: String,
        default: ''
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
    data () {
      return {
        title: '고객선택', // 화면명

        selectRdo: '', // 선택한 라디오 값
        custNm: '', // 조회할 고객명 입력값
        lv_UserInfo: {}, // 사용자 정보
        selectedItem: {}, // 선택한 값 Obj
        CnsntCustList: [], // 기존 고객 리스트

        iCHoshInfoList: [],
        isEmptyView: false,

        /************************** CSS Style **************************/
        // index-scroll 초성스크롤 위치지정  스크롤에 따른 위치변경은 개발에서
        indexStyle: {
          top:'150px',
          bottom:'100px',
          height:'calc(100vh - 345px)',
          position:'fixed'
        },
      };
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM046P')

      this.fn_Init()
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
    methods: {
      /*********************************************************
       * @function fn_Init
       * @notice   화면진입시, 최초 실행 함수
       *           초기 셋팅 및 설정
      *********************************************************/
      fn_Init () {
        this.custNm = this.pCustNm

        // 서비스 호출
        this.fn_SearchCustomerInfo()
      },
      /******************************************************************************
       * @function fn_SearchCustomerInfo
       * @notice   고객명으로 고객 정보활용 동의 정보 조회 서비스 호출
      ******************************************************************************/
      fn_SearchCustomerInfo () {
        const lv_Vm = this

        let pParams = { custNm: lv_Vm.custNm.trim() } // 고객명

        const trnstId = 'txTSSCM10S4'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(res => {
            // 필수 컨설팅 리스트
            if (!_.isEmpty(res.body) && !_.isEmpty(res.body.cMInfoPrcusCnsntListVO)) {
              lv_Vm.CnsntCustList = res.body.cMInfoPrcusCnsntListVO

              if (lv_Vm.CnsntCustList.length > 0) {
                lv_Vm.isEmptyView = false

                lv_Vm.CnsntCustList.forEach(item => {
                  if (item.infoPrcusMcnstYn === 'Y') { // 필수컨설팅 동의 여부
                    if (item.infoPrcusMktYn === 'Y') { // 마케팅 동의 여부
                      item.infoPrcusCnsntTypCd = '18'
                    } else {
                      item.infoPrcusCnsntTypCd = '17'
                    }
                  } else {
                    if (item.infoPrcusMktYn === 'Y') { // 마동만
                      item.infoPrcusCnsntTypCd = '19'
                    }
                  }
                });
                
              } else {
                lv_Vm.isEmptyView = true
              }
            } else {
              lv_Vm.CnsntCustList = []
              lv_Vm.isEmptyView = true
            }
          })
          .catch(error => {
            window.vue.error(error)
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            lv_Vm.CnsntCustList = []
            lv_Vm.isEmptyView = true
          });

      },
      /******************************************************************************
       * @function fn_CheckSelectedItem
       * @notice   row 선택 시 호출. 선택된 row의 radio value를 변경
      ******************************************************************************/
      fn_CheckSelectedItem (item) {
        this.selectedItem = item
        this.selectRdo = item.chnlCustId
      },
      /******************************************************************************
       * @function fn_ConfirmContent
       * @notice   확인버튼 선택 시 호출 - 기존고객 확인 조회 실행
      ******************************************************************************/
      fn_ConfirmContent () {
        const lv_Vm = this

        if (!this.$bizUtil.isEmpty(this.selectedItem.chnlCustId) && this.selectedItem.unAprvCustYn === 'N') { // 승인 고객만 고객카드 조회 호출 가능
          this.fn_isExistCustClick()
        } else { // 미승인 고객은 동의서 발행 세대원 조회 불가능함. alert띄우고 종료
          let contentMsg = this.$t('cm')['ECMC046']

          let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              single: true,
              title: "",
              content: contentMsg
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(lv_AlertPop)
                lv_Vm.fn_Cancle()
              }
            }
          });

        }
      },
      /*********************************************************
       * @function fn_isExistCustClick
       * @notice   기존고객 확인 조회 => 세대원 정보 추출 목적
       *           세대원 셋팅 후, 해당 팝업 닫기
      *********************************************************/
      fn_isExistCustClick () {
        const lv_Vm = this

        let pParams = {
                        custNm: this.selectedItem.custNm, // 고객명
                        chnlCustId: this.selectedItem.chnlCustId, // 채널고객ID
                        mnCustRltnCd: this.selectedItem.mnCustRltnCd // 채널고객관계구분코드
                      }
                  
        const trnstId = 'txTSSCM11S2'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && !_.isEmpty(res.body.iCCustArsLinkHoshInfoListInqrVO)) {

            // 선택한 고객의 세대정보중, 선택한 고객을 제외
            let hoshList = res.body.iCCustArsLinkHoshInfoListInqrVO.filter(value =>{
                return value.chnlCustId !== lv_Vm.selectedItem.chnlCustId
              }); // 선택한 항목은 제외한 나머지 list를 저장

            hoshList.forEach(item => {
                let lv_Item = {}
                lv_Item.key = item.chnlCustId
                lv_Item.label = item.custNm
                lv_Item.cnsltFileId = item.chnlCustId
                lv_Item.famCsttNm = item.custNm
                lv_Item.mnCustRltnCD = item.inqrScCd
                lv_Vm.iCHoshInfoList.push(lv_Item)
              });

          }
          lv_Vm.fn_Confirm()
        })
        .catch(error => {
          window.vue.error(error)
        });

      },
      /******************************************************************************
       * @function fn_Cancle//fn_Confirm
       * @notice   fn_Cancle: 해당 팝업 닫기 / (뒤로가기, 취소, 돋보기) 버튼
       *           fn_Confirm: 선택한 세대원정보(본인포함) 전달하며, 해당 팝업 닫기 / 확인 버튼
      ******************************************************************************/
      fn_Cancle () { this.$emit('onPopupCancel') },
      fn_Confirm () {
        let custInfoVO = this.CnsntCustList.filter(item => {
          return item.chnlCustId === this.selectRdo
        })[0]
        
        this.$emit( 'onPopupConfirm', {custInfoVO: custInfoVO, iCHoshInfoList: this.iCHoshInfoList} )
      },

    } // ::Methods End
  };
</script>

/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM044P
 * 화면설명 : 동의서발행 팝업
 * 접근권한 : 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일 : 2022.12.28
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="동의서 발행" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">

        <!-- ::Contents 영역 Start:: -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30 mb50">
          <span class="ns-title mb10">동의서 유형</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="agreeTypes" class="chip-type-wrap">
              <mo-segment-button value="17">필수컨설팅</mo-segment-button>
              <mo-segment-button value="19">마케팅</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container v-if="agreeTypes === '17'" alignV="start" componentid="" direction="column" class="ns-input-box mb45">
          <span class="ns-title mb10">출력방식</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="summaryTypes" class="chip-type-wrap">
              <mo-segment-button value="1">상세</mo-segment-button>
              <mo-segment-button value="2">요약 + 상세</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mb45">
          <span class="ns-title mb10">구분</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="custSelectTypes" class="chip-type-wrap">
              <mo-segment-button value="1">고객 미선택</mo-segment-button>
              <mo-segment-button value="2">고객 선택</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container  v-show="custSelectTypes === '2'" alignV="start" componentid="" direction="column" class="ns-input-box mb20">
          <span class="ns-title mb10">대상</span>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field ref="custNmRef" type="searchable" :rules="validateRuleCustNm" v-model="srchCustNm" underline maxlength="30"
              placeholder="고객명을 입력해주세요" @keyup.enter="fn_CheckCustNm('popup')" @click-icon="fn_CheckCustNm('popup')" searchable/>
          </ur-box-container>
        </ur-box-container>

        <!-- 세대원 선택 -->
        <ur-box-container  v-if="custSelectTypes === '2' && iCHoshInfoList.length-1 > 0" alignV="start" componentid="" direction="column" class="ns-input-box mb80">
          <ur-box-container alignH="start" componentid="" direction="row" class="form-mix-type-1 mb10">
            <div class="ns-check"><mo-checkbox v-model="hoshItems[0].check" :disabled="iCHoshInfoList.length-1 < 1">세대원1</mo-checkbox></div>
            <msp-bottom-select ref="bottomSelect" :items="iCHoshInfoList" v-model="hoshItems[0].value" @input="fn_OnSelect('0', hoshItems[0])" :disabled="!hoshItems[0].check"
                                underline class="ns-dropdown-sheet" placeholder="선택" bottom-title="선택" closeBtn/>
          </ur-box-container>
          <ur-box-container alignH="start" componentid="" direction="row" class="form-mix-type-1 mb10">
            <div class="ns-check"><mo-checkbox v-model="hoshItems[1].check" :disabled="iCHoshInfoList.length-1 < 2">세대원2</mo-checkbox></div>
            <msp-bottom-select ref="bottomSelect" :items="iCHoshInfoList" v-model="hoshItems[1].value" @input="fn_OnSelect('1', hoshItems[1])" :disabled="!hoshItems[1].check"
                                underline class="ns-dropdown-sheet" placeholder="선택" bottom-title="선택" closeBtn/>
          </ur-box-container>
          <ur-box-container alignH="start" componentid="" direction="row" class="form-mix-type-1 mb10">
            <div class="ns-check"><mo-checkbox v-model="hoshItems[2].check" :disabled="iCHoshInfoList.length-1 < 3">세대원3</mo-checkbox></div>
            <msp-bottom-select ref="bottomSelect" :items="iCHoshInfoList" v-model="hoshItems[2].value" @input="fn_OnSelect('2', hoshItems[2])" :disabled="!hoshItems[2].check"
                                underline class="ns-dropdown-sheet" placeholder="선택" bottom-title="선택" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        
        <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClosePop">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Print" :disabled="(custSelectTypes === '2' && !isShrchCust)">인쇄</mo-button>
          </div>
        </ur-box-container>
        
        <!-- 세대원 중복 안내 bottomSheet -->
        <mo-bottom-sheet ref="agreeCnfrmPop" class="ns-bottom-sheet ns-style3">
          <!-- <template v-slot:title></template> -->
          <div>
          <p class="cancel-ment">동일한 세대원(자녀)을 선택하였습니다.</p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CnfrmClose">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

      </ur-box-container>
      <!-- ::Contents 영역 End:: -->

    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'

import MSPCM046P from '@/ui/cm/MSPCM046P' // 고객선택

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: "MSPCM044P",
  // 현재 화면 ID
  screenId: "MSPCM044P",
  props: {
    pCustNm: {
      type: String,
      default: ''
    },
    pCustCardVO: {
      type: Object,
      default: {}
    }
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
      lv_UserInfo: {}, // 사용자 정보
      agreeTypes: '17', // (필수컨설팅: 17 / 마케팅: 19)
      summaryTypes: '1', // (상세: 1 / 요약 + 상세: 2)
      custSelectTypes: '1', // 고객 미 선택: 1 / 고객 선택: 2

      selectedItem: [], // 선택한 값 Obj
      selectDefaultObj: {text: '선택', value: ''},

      srchCustNm: '', // 고객명 입력값
      popupObj: {}, // Popup Object
      custCardVO: {},
      isEmptyView: false,

      iCHoshInfoList: [], // 세대원 리스트
      hoshOriginList: [], // 세대원 리스트
      hoshSelectList: [], // 세대원 리스트

      // 선택된 세대원
      hoshItems: [
        {check: false, value: ''}, // 세대원1
        {check: false, value: ''}, // 세대원2
        {check: false, value: ''}, // 세대원3
      ],

      validateRuleCustNm: [
        v => !!v || '필수입력항목입니다',
        () => this.custNmResult || this.custNmErrorMsg
      ],

      isShrchCust: false,

      ShowBottomBtn: true // 키패드 노출시, 버튼 숨김
    };
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    custSelectTypes(value) {
      if (value === '2') {
        this.fn_CheckCustNm()
        this.$refs.custNmRef.validate()
      }
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    const lv_Vm = this

    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보

    // ************ 키패드 이벤트 등록 ************ //
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키패드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키패드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })

  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM044P')

    this.fn_Init()
  },
  destroyed() {
    // 키패드 이벤트 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      this.custCardVO = this.pCustCardVO
      this.srchCustNm = this.custCardVO.custNm

      if (!_.isEmpty(this.custCardVO) && this.custCardVO.isExistCustYN === 'Y') {
        this.custSelectTypes = '2'
        this.fn_SelListArsWcnstHoshInfo()
      }
    },
    /******************************************************************************
     * @function fn_SelListArsWcnstHoshInfo
     * @notice   동의서 발행을 위한 세대원 정보 조회 I/F 호출
    ******************************************************************************/
    fn_SelListArsWcnstHoshInfo () {
      const lv_Vm = this
      let custRltnCd = lv_Vm.custCardVO.mnCustRltnCd ? lv_Vm.custCardVO.mnCustRltnCd : '01'
      let pParams = { 
                      custNm: lv_Vm.custCardVO.custNm, // 고객명
                      chnlCustId: lv_Vm.custCardVO.chnlCustId, // 채널고객ID
                      mnCustRltnCd: custRltnCd // 채널고객관계구분코드
                    }
      
      const trnstId = 'txTSSCM11S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (res.body !== null) {
            if (res.body.iCCustArsLinkHoshInfoListInqrVO !== null && res.body.iCCustArsLinkHoshInfoListInqrVO.length > 0) {
              let hoshInfoList = res.body.iCCustArsLinkHoshInfoListInqrVO.filter(value => {
                  value.chnlCustId !== lv_Vm.custCardVO.chnlCustId
                });

              hoshInfoList.forEach(item => {
                if (!lv_Vm.$bizUtil.isEmpty(item.chnlCustId)) {
                  item.value = item.chnlCustId
                  item.text = item.custNm
                }
              });

              hoshInfoList = hoshInfoList.filter(item => {
                  return item.mnCustRltnCd !== '02'
                });

              lv_Vm.iCHoshInfoList = [lv_Vm.selectDefaultObj, ...hoshInfoList]
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_OpenPop
     * @name     팝업호출
     * @notice   고객 선택 팝업 호출
    *********************************************************/
    fn_OpenPop () {
      const lv_Vm = this
      
      let popComponent = null

      // MSPCM045P, MSPCM046P 팝업 무한 중첩이 가능하여, MSPCM046P로 이동하기로 (2022.12.23)
      popComponent = MSPCM046P // 고객 선택

      // 고객 조회 팝업창 띄우기
      this.popupObj = lv_Vm.$bottomModal.open(popComponent, {
        properties: {
          pCustNm: this.srchCustNm.trim(), // 고객명 파라미터
          pCustKnbScCd: this.custCardVO.knbScCd // 내/외국인 코드
        },
        listeners: {
          onPopupConfirm: (pData) => {
            console.log('iCHoshInfoList', pData)
            lv_Vm.isShrchCust = true
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)

            let hoshInfoList = pData.iCHoshInfoList
            if (!_.isEmpty(pData.custInfoVO)) {
              lv_Vm.custCardVO = pData.custInfoVO
              lv_Vm.srchCustNm = pData.custInfoVO.custNm
              lv_Vm.custCardVO.chnlCustId = pData.custInfoVO.chnlCustId
            }

            hoshInfoList.forEach(item => {
              if (!lv_Vm.$bizUtil.isEmpty(item.key)) {
                item.value = item.key
                item.text = item.label
              }
            });

            hoshInfoList = hoshInfoList.filter(item => {
                return item.mnCustRltnCd !== '01'
              });

            lv_Vm.iCHoshInfoList = [lv_Vm.selectDefaultObj, ...hoshInfoList]
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
          },
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
          }
        }
      });

    },
    /******************************************************************************
    * @function fn_CheckCustNm
    * @notice   신규일 때 고객명 입력 후 focus out 될 때 호출
    ******************************************************************************/
    fn_CheckCustNm (param) {
      this.$refs.custNmRef.clearValidation()
      this.srchCustNm = this.srchCustNm ? this.srchCustNm.toUpperCase() : ''

      let lv_CustNmResult = this.srchCustNm
      let regexp_eng = '[a-zA-Z]' // 영문(대소)
      
      if (this.$bizUtil.isEmpty(lv_CustNmResult)) {
        this.custNmResult = false // 이름 유효성 체크 결과
        if (param === 'popup') {
          this.$refs.custNmRef.validate()
        }
        return
      }

      // 한글 10글자, 영문은 30글자 제한
      if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
        if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
          lv_CustNmResult = lv_CustNmResult.substr(0, 30)
        } else {
          lv_CustNmResult = lv_CustNmResult.substr(0, 10)
        }
      }

      if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
        this.custNmResult = true // 이름 유효성 체크 결과
      } else {
        this.custNmResult = false // 이름 유효성 체크 결과
        this.custNmErrorMsg = '고객명을 입력해주세요.'
      }

      this.$refs.custNmRef.validate()
      if (this.custNmResult && param === 'popup') {
        this.fn_OpenPop()
      }
      return this.custNmResult
    },
    /******************************************************************************
     * @function fn_Print
     * @notice   인쇄 버튼 선택 시 호출 - 인쇄 IF 호출
    ******************************************************************************/
    fn_Print () {
      const lv_Vm = this

      if (this.lv_UserInfo.userScCd === '13' || this.lv_UserInfo.userScCd === '93') {
        this.getStore('confirm').dispatch('ADD', `${this.$t('cm')['ECMC081']}`) // '위촉전 교육생'은 동의서 발행이 불가합니다.
        return
      }
      let lv_Param = {
                      wcnstPblInfoVO: {},
                      famCsttVO: [],
                      dgrstReqCd: 'S' // 진단결과요청코드
                    }

      let lv_WcnstPblInfoVO = {
                                // 정보활용동의유형코드
                                infoPrcusCnsntTypCd: this.agreeTypes, // 17: 필컨, 19: 마동, 18: 둘다
                                srvcInflPathCd: '6', // 서비스 유입 경로 - 5 : 유파트너, 6 : 사랑온(태블릿)
                                pblScCd: '20', // 발행전송구분코드 - 사랑온은 [인트라넷 예약발행 : 20] 만 가능
                                wcnstPblSc: (this.agreeTypes === '17') ? this.summaryTypes : '' // 출력구분 추가 22.01.18
                              }
      lv_Param.wcnstPblInfoVO = lv_WcnstPblInfoVO

      if (this.custSelectTypes === '2' && !this.$bizUtil.isEmpty(lv_Vm.custCardVO.chnlCustId)) { // 고객이 선택되어져 있는 경우
        lv_Param.custNm = lv_Vm.custCardVO.custNm
        lv_Param.chnlCustId = lv_Vm.custCardVO.chnlCustId
        lv_Param.mnCustRltnCd = lv_Vm.custCardVO.mnCustRltnCd

        // 선택된 세대원 세팅
        let famCsttVO = this.iCHoshInfoList.filter(item => {
            for (let i=0; i<lv_Vm.hoshSelectList.length; i++) {
              if (item.cnsltFileId === lv_Vm.hoshSelectList[i].value) {
                item.cnsltFileId = item.cnsltFileId
                item.famCsttNm = item.famCsttNm // 고객명
                
                return item
              }
            } // for i
          });
        
        lv_Param.famCsttVO = [...famCsttVO]

        // 주고객 세팅
        if (!this.$bizUtil.isEmpty(lv_Vm.custCardVO.chnlCustId)) {
          let lv_Item = {}
          lv_Item.cnsltFileId = lv_Vm.custCardVO.chnlCustId
          lv_Item.famCsttNm = lv_Vm.custCardVO.custNm
          lv_Item.mnCustRltnCD = lv_Vm.custCardVO.mnCustRltnCd
          lv_Param.famCsttVO.push(lv_Item)
        }
      }

      const trnstId = 'txTSSCM11I0'
      const auth = 'S'
      this.post(lv_Vm, lv_Param, trnstId, auth)
        .then(res => {
          if (res.body !== null && res.body.trnsRsltCntnt !== null) {
            // lv_Vm.getStore('confirm').dispatch('ADD', res.body.trnsRsltCntnt)
            lv_Vm.getStore('confirm').dispatch('ADD', '출력되었습니다.')

            if (res.body.trnsRsltCd === 'S') { // 정상일 경우 팝업창 닫힘
              // console.log('인쇄I/F 성공', response)
              
              // 팝업 유지 요청 (2023.08.01)
              // lv_Vm.$emit('onPopupConfirm')
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });
        
    },
    /*********************************************************
     * @function fn_OnSelect
     * @notice   세대원 선택 (세대원 선택 총 3개)
     * @param    index: 선택 selectBox Index
     * @param    sItem: 선택 세대원
    *********************************************************/
    fn_OnSelect (index, sItem) {
      const lv_Vm = this
      let chk = 0

      this.hoshSelectList = [...this.hoshItems.filter(item => item.check)]

      this.hoshSelectList.forEach(item => {
        if (!lv_Vm.$bizUtil.isEmpty(sItem.value) && item.value === sItem.value) {
          chk++;
        }
      });

      if (chk > 1) {
        // alert
        this.hoshItems[index].value = ''
        this.fn_CnfrmOpen()
      }
    },
    /**************************************************************************************
     * @function fn_CnfrmClose//fn_CnfrmOpen
     *           
     * @notice   refs.agreeCnfrmPop: 동일한 세대원 선택하였습니다.
     *           fn_CnfrmOpen : 모달 Open
     *           fn_CnfrmClose: 모달 Close
    ***************************************************************************************/
    fn_CnfrmOpen () { this.$refs.agreeCnfrmPop.open() },
    fn_CnfrmClose () { this.$refs.agreeCnfrmPop.close() },

    /*********************************************************
     * @function fn_ClosePop
     * @notice   취소 버튼 클릭
     *           팝업 닫기
    *********************************************************/
    fn_ClosePop () { this.$emit('onPopupCancel') },
  } // ::Methods End
};
</script>
